import {motion} from "framer-motion";
import "../../styles/Header.css";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

//miei componenti
import useWindowDimensions from "../../utility/useWindowDimensions";

//icone
import UseAnimations from "react-useanimations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faEnvelope} from "@fortawesome/free-solid-svg-icons";

import arrowUp from "react-useanimations/lib/arrowUp";

export default function MiniHeader() {
  const {width} = useWindowDimensions();

  let animation_smallscreen = {
    animate: {y: 0},
    initial: {y: "+1000px"},
    exit: {y: +1000},
    transition: {duration: 0.2},
  };
  let animation_bigscreen = {
    animate: {y: 0},
    initial: {y: "-1000px"},
    exit: {y: -1000},
    transition: {duration: 0.2},
  };
  return (
    <motion.header>
      {width < 640 ? (
        <motion.div
          className="navbar-container z-10"
          animate={animation_smallscreen.animate}
          initial={animation_smallscreen.initial}
          exit={animation_smallscreen.exit}
          transition={animation_smallscreen.transition}
        >
          <div className="navbar-content items-center">
            <a href="#cardHeader" className="navbar-text">
              <UseAnimations
                // className="navbar-icon pl-2 "
                className="navbar-icon"
                speed={1}
                animation={arrowUp}
                // size={55}
                size={35}
                strokeColor="black"
              />
              Top
            </a>

            <Link to={"/contact"}>
              <div className="navbar-text">
                <FontAwesomeIcon
                  icon={faLink}
                  className="text-black my-auto mr-1"
                />
                Links
              </div>
            </Link>
          </div>
        </motion.div>
      ) : (
        <motion.div
          className="navbar-container-bg"
          animate={animation_bigscreen.animate}
          initial={animation_bigscreen.initial}
          exit={animation_bigscreen.exit}
          transition={animation_bigscreen.transition}
        >
          <div className="navbar-content-bg">
            <div className="navbar-nome-bg">
              <a href="#cardHeader">Duccio Meconcelli </a>{" "}
            </div>

            <Link to="/contact" className="navbar-iconlink-bg">
              <FontAwesomeIcon
                icon={faLink}
                className="text-black my-auto mr-1"
              />
            </Link>

            <motion.div
              animate={{y: 0}}
              initial={{y: 0}}
              exit={{y: 0}}
              transition={{duration: 0.2}}
            >
              <a href="#cardHeader" className="navbar-text navbar-action-bg">
                <UseAnimations
                  // className="navbar-icon pl-2 "
                  className="navbar-icon-bg"
                  speed={1}
                  animation={arrowUp}
                  // size={55}
                  size={35}
                  strokeColor="black"
                />
              </a>
            </motion.div>
          </div>
        </motion.div>
      )}
    </motion.header>
  );
}
