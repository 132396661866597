import {run} from "../playcore/run.js";
import * as program from "../playcore/programs/demos/wobbly";

export default function run_b(once = false) {
  run(
    program,

    {
      //once: true, // if set to true the renderer will run only once
      fps: 35,
      element: document.querySelector("pre"),
    }
  );
  // .then(function (e) {
  //   // console.log(e);
  // })
  // .catch(function (e) {
  //   console.warn(e.message);
  //   console.log(e.error);
  // });
}
