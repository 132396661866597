import {useEffect, useState, useRef} from "react";
import {motion} from "framer-motion";

import {useNavigate, Link} from "react-router-dom";

import "../styles/Article.css";
import post from "../dati/posts.json";

//miei componenti
import Navbar from "./Navbar";

//icone
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";

export default function Article({id}) {
  const [post_found, setPost_found] = useState(null);
  const history = useNavigate();

  const [next_id, setNext_id] = useState(null);
  const asterischi = useRef([]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const found = post.find((element) => String(element.id) === id);
    if (found == undefined) {
      setPost_found(false);
      return;
    }
    post.map((el, index) => {
      if (String(el.id) === id) {
        //controllo se è presente l'articolo dopo
        if (post[index + 1] != undefined) {
          let i = index + 1;

          while (i < post.length) {
            if (post[i].visibile == true) {
              setNext_id(i);
              i = post.length;
            }
            i = i + 1;
          }
        } else {
          setNext_id(null);
        }

        setPost_found(found);
        return;
      }
    });
  }, [id]);

  return (
    <>
      <div onClick={() => history("/")} className="article">
        {post_found && (
          <motion.div
            onClick={(e) => {
              e.stopPropagation();
            }}
            initial={{
              y: +1000,
            }}
            animate={{
              y: 0,
            }}
            exit={{y: +1000}}
            transition={{
              duration: 0.3,
              // ease: "easeInOut",
            }}
            className="article-conteiner"
          >
            <motion.div>
              {post_found.copertina ? (
                <img
                  src={post_found.copertina}
                  className="article-image"
                  alt=""
                />
              ) : (
                <div className="trasparent-image"></div>
              )}

              <div className="article-titolo">
                <a href={post_found.link_titolo}>{post_found.titolo} </a>
              </div>
              <div className="article-paragrafo">
                {post_found.paragrafo.map((par) => {
                  return (
                    <div key={par}>
                      <div className="mb-2">
                        <div dangerouslySetInnerHTML={{__html: par}} />
                      </div>
                    </div>
                  );
                })}

                <div>
                  <a href={post_found.link_titolo}>
                    <div className="text-xl mt-5">
                      <FontAwesomeIcon
                        icon={faLink}
                        className="text-white text-xl"
                      />
                      Link
                    </div>
                  </a>
                </div>
              </div>
              <div className="divider"></div>

              <div className="article-footer">
                {next_id && (
                  <div className="text-center">
                    .
                    <br />
                    .
                    <br />
                    .
                    <br />
                    <div onClick={scrollToTop}>
                      Next:{" "}
                      <Link to={"/article/" + post[next_id].id}>
                        {post[next_id].titolo}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>
      <Navbar />
    </>
  );
}
