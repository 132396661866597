import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {RemoveScroll} from "react-remove-scroll";

// Miei componenti:
import Header from "./Header/Header";
import List from "./List";
import Article from "./Article";
import Contact from "./Contact";
import Appunti from "./Appunti";
import NotFound404 from "./NotFound404";

// Utility:
import run_b from "../utility/background-script";
import "../styles/App.css";

//---background
run_b();

// ---------------

function Home_component(props) {
  let params = useParams().params;
  const location = useLocation();

  let contact = location.pathname === "/contact" ? true : false;

  let pagina = params !== "contact" ? params : false;

  const imageHasLoaded = true;

  return (
    <>
      <Header />
      <List />

      <RemoveScroll
        enabled={contact ? true : false}
        allowPinchZoom={false}
        removeScrollBar={true}
      >
        <AnimatePresence>
          {contact && imageHasLoaded && <Contact key="contact" />}
        </AnimatePresence>
      </RemoveScroll>

      <RemoveScroll
        enabled={pagina ? true : false}
        allowPinchZoom={false}
        removeScrollBar={true}
      >
        <AnimatePresence>
          {pagina && imageHasLoaded && <Article key="pagina" id={pagina} />}
        </AnimatePresence>
      </RemoveScroll>
    </>
  );
}

const Home = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home_component />} />
        <Route path="/article/:params" element={<Home_component />} />
        <Route path="/contact" element={<Home_component />} />
        <Route
          path="/contact/uni"
          element={
            <>
              <Header />
              <Appunti />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <Header />
              <NotFound404 />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Home;
