import {motion} from "framer-motion";
import {useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

import Footer from "./Footer";
import "../styles/Contact.css";
import "../styles/Footer.css";
import links from "../dati/links.json";
import TextScramble from "../utility/Text-animation-ascii";

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function Contact({className = ""}) {
  const history = useNavigate();
  const ref_links = useRef([]);

  useEffect(() => {
    ref_links.current.map((iterator, index) => {
      const el = iterator;
      const fx = new TextScramble(el);

      const next = () => {
        fx.setText(links[index].titolo_link).then(() => {
          setTimeout(next, randomIntFromInterval(3500, 10000));
        });
      };
      return next();
    });
  }, []);

  return (
    <div onClick={() => history("/")} className="contact">
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="contact-card"
        initial={{
          y: +1000,
        }}
        animate={{
          y: "20rem",
        }}
        exit={{y: +1000}}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
      >
        <div className="link-title">Social Garbage</div>
        <br />

        <motion.div id="link">
          {links.map((link, index) => {
            if (link.titolo_link == "Appunti + Tesi") {
              return (
                <Link to={"/contact/uni"} className="link" key={link.id}>
                  <div
                    className="link-text"
                    ref={(el) => (ref_links.current[index] = el)}
                  ></div>

                  <div className="section"></div>
                </Link>
              );
            } else {
              return (
                <div key={link.id}>
                  {link.sezione === "file" ? (
                    <a
                      href={process.env.PUBLIC_URL + "/" + link.link}
                      className="link"
                    >
                      <div
                        className="link-text"
                        ref={(el) => (ref_links.current[index] = el)}
                      ></div>

                      <div className="section"></div>
                    </a>
                  ) : (
                    <a
                      href={link.link}
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className="link-text"
                        ref={(el) => (ref_links.current[index] = el)}
                      ></div>

                      <div className="section"></div>
                    </a>
                  )}
                </div>
              );
            }
          })}

          <div>
            <Footer />
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{opacity: 0}}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        className="flex justify-center"
      >
        <Link to="/" className="button-back">
          <FontAwesomeIcon icon={faXmark} />
        </Link>
      </motion.div>
    </div>
  );
}
