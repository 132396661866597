import {motion} from "framer-motion";
import "../../styles/Header.css";
import Logo from "./Logo";
import * as React from "react";
import {useIntersection} from "react-use";
import MiniHeader from "./MiniHeader";
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faTelegram, faInstagram} from "@fortawesome/free-brands-svg-icons";

import cits from "../../dati/cit.json";

export default function Header() {
  const cardHeader = React.useRef(null);

  const [citazione, setCitazione] = React.useState("");
  React.useEffect(() => {
    setCitazione(cits[Math.floor(Math.random() * cits.length)].cit);
  }, []);

  const intersection = useIntersection(cardHeader, {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  let animazione = {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
      // ease: "easeInOut",
    },
  };

  if (intersection && intersection.intersectionRatio < 0.2) {
    animazione.opacity = 0.4;
    animazione.y = "-100px";
  } else {
    animazione.opacity = 1;
    animazione.y = "0px";
  }
  return (
    <header id="home">
      {intersection && intersection.intersectionRatio < 0.2 ? (
        <>
          <MiniHeader />
        </>
      ) : null}

      <motion.div
        className="cardHeader"
        id="cardHeader"
        ref={cardHeader}
        animate={animazione}
        exit={{
          y: -1000,
          transition: {duration: 0.1},
        }}
      >
        <h1 className="">
          <Logo width={"150px"} height="150px" className="shadowfilter logo" />
          <br />
          <a className="cardHeader-nome" href="https://duccio.me/">
            Duccio Meconcelli
          </a>
        </h1>
        <h2 className="cardHeader-citazione" id="quote">
          <tt>"{citazione}"</tt>
        </h2>
        <h2 className="cardHeader-icon-container">
          <a
            target="_blank"
            href="https://t.me/Duccioo"
            className="cardHeader-icon"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTelegram} className=" text-black" />
          </a>{" "}
          <a
            target="_blank"
            href="mailto:meconcelliduccio@gmail.com"
            className="cardHeader-icon"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faEnvelope} className=" text-black" />
          </a>{" "}
          <a
            target="_blank"
            href="https://www.instagram.com/dosiomancobelli/"
            className="cardHeader-icon"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} className=" text-black" />
          </a>
        </h2>

        <Link to="/contact">
          <div className="cardHeader-link">All Other Stupid Links</div>
        </Link>
      </motion.div>
    </header>
  );
}
