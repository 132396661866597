import "../styles/Footer.css";

export default function Footer({className = ""}) {
  return (
    <footer id="contatti" className={`footer ${className}`}>
      {/* Copyright &copy; */} Copyright {" "}
      <span id="copyright">{new Date().getFullYear()}</span>{" "}
      <a href="https://duccio.me/">Duccio Meconcelli</a>. <br /> All Rights
      Reserved.
    </footer>
  );
}
