import {motion} from "framer-motion";
import {useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

import Footer from "./Footer";
import "../styles/Contact.css";
import "../styles/Footer.css";
import appunti from "../dati/appunti.json";
import TextScramble from "../utility/Text-animation-ascii";

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function Appunti() {
  const history = useNavigate();
  const ref_links = useRef([]);

  useEffect(() => {
    ref_links.current.map((iterator, index) => {
      const el = iterator;
      const fx = new TextScramble(el);

      const next = () => {
        fx.setText(appunti[index].titolo).then(() => {
          setTimeout(next, randomIntFromInterval(3200, 7000));
        });
      };
      return next();
    });
  }, []);

  return (
    <div onClick={() => history("/")} className="contact">
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="contact-card"
        initial={{
          y: +1000,
        }}
        animate={{
          y: 0,
        }}
        exit={{y: +1000}}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
      >
        <div className="link-title">📓Appunti + Tesi📓</div>
        <br />

        <motion.div id="link">
          {/* Appunti triennale */}
          <div className="link-text text-2xl">
            Appunti Triennale Ingegneria Informatica:
          </div>

          {appunti.map((app, index) => {
            if (app.sezione == "Triennale") {
              return (
                <div key={app.id}>
                  <a
                    href={app.link}
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="link-text mb-4 pl-5"
                      ref={(el) => (ref_links.current[index] = el)}
                    ></div>
                  </a>
                </div>
              );
            }
          })}
          <div className="link-text text-3xl text-center mx-auto">*</div>
          {/* Tesi Triennale */}
          <div className="link-text text-2xl">Tesi Triennale:</div>
          {appunti.map((app, index) => {
            if (app.sezione == "Tesi Triennale") {
              return (
                <div key={app.id}>
                  <a
                    href={app.link}
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="link-text pl-5"
                      ref={(el) => (ref_links.current[index] = el)}
                    ></div>
                  </a>
                </div>
              );
            }
          })}
          <br />
          <div className="section"></div>

          {/* Altri Apunti */}
          <div>
            <div className="link-text text-2xl text-left mb-3">
              Per altri appunti o informazioni:
            </div>

            <div>
              <a
                href={"mailto:meconcelliduccio@gmail.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="link-text mb-5 pl-5">Email</div>
              </a>
            </div>
            <div>
              <a
                href={"https://t.me/Dosium"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="link-text pl-5">Telegram</div>
              </a>
            </div>
          </div>
          <br />

          <div className="link-text text-3xl text-center mx-auto">*</div>
          <div className="link-text text-3xl text-center mx-auto">*</div>
          <div className="link-text text-3xl text-center mx-auto">*</div>
          <div>
            <Footer />
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{opacity: 0}}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        className="flex justify-center"
      >
        <Link to="/" className="button-back">
          <FontAwesomeIcon icon={faXmark} />
        </Link>
      </motion.div>
    </div>
  );
}
