import posts from "../dati/posts.json";
import "../styles/List.css";
import {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import textFit from "../utility/textFit.min.js";
import isItDark from "../utility/light_dark_img";
import {FastAverageColor} from "fast-average-color";

var FontFaceObserver = require("fontfaceobserver");

export default function List() {
  var lines = useRef([]);

  let index_parole_totale = 0;

  useEffect(() => {
    const font = new FontFaceObserver("Poppins");
    font.load().then(function () {
      for (const iterator of lines.current) {
        textFit(iterator, {
          alignVert: false,
          alignHoriz: false,
          multiLine: true,
          detectMultiLine: false,
          minFontSize: 10,
          maxFontSize: 500,
          reProcess: true,
          widthOnly: false,
          alignVertWithFlexbox: false,
        });
      }
    });
  }, []);

  return (
    <>
      {posts.map((post, index) => {
        if (post.visibile !== true) {
        } else {
          return (
            <li className={`card`} key={post.id}>
              <Link
                to={"/article/" + post.id}
                className="card-content-container"
              >
                <div className="card-content trasparent">
                  {post.copertina && (
                    <div className="card-image-container">
                      <img
                        className="card-image max-w-full w-full h-full object-cover"
                        src={post.copertina}
                        style={{}}
                        alt="background of article list"
                      />
                    </div>
                  )}

                  <div>
                    <div
                      className={" list-titolo"}
                      style={{
                        height: 400,
                      }}
                      ref={(el) => {
                        lines.current[index_parole_totale] = el;
                        index_parole_totale++;
                      }}
                    >
                      {post.titolo}
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          );
        }
      })}
    </>
  );
}
