import "../../styles/Header.css";
import {motion} from "framer-motion";

const svgVariants = {
  hidden: {},
  visible: {},
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1.3,
      ease: "easeInOut",
    },
  },
};

export default function Logo({height, width, className}) {
  return (
    <>
      <motion.svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
        variants={svgVariants}
        initial="hidden"
        animate="visible"
        style={{height: height, width: width}}
      >
        <motion.path
          d="M475.345 412.057L475.345 73.9304L819.341 512L183.53 456.743L475.345 976.269L475.345 573.269"
          fill="none"
          opacity="1"
          stroke="#ffea00"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40.0518"
          variants={pathVariants}
        />
        <motion.path
          d="M533.299 573.269L533.299 976.269L646.157 976.269L646.157 573.654"
          fill="none"
          opacity="1"
          stroke="#ffea00"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40.0518"
          variants={pathVariants}
        />
        <motion.path
          d="M819.341 573.269L711.933 573.269L711.933 976.269L815.701 976.269"
          fill="none"
          opacity="1"
          stroke="#ffea00"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40.0518"
          variants={pathVariants}
        />
      </motion.svg>
    </>
  );
}
