import {motion} from "framer-motion";
import "./../styles/Header.css";

import {useState, useEffect} from "react";
import {Link, useParams, useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";

import UseAnimations from "react-useanimations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faLink} from "@fortawesome/free-solid-svg-icons";
import arrowUp from "react-useanimations/lib/arrowUp";
import useWindowDimensions from "../utility/useWindowDimensions";

export default function Navbar({back = "", next_id}) {
  let history = useNavigate();
  const {id} = useParams();

  const {height, width} = useWindowDimensions();
  const [small_screen, setSmall_screen] = useState(false);

  let animation_smallscreen = {
    animate: {y: 0},
    initial: {y: "+1000px"},
    exit: {y: +1000},
    transition: {duration: 0.2},
  };
  let animation_bigscreen = {
    animate: {y: 0},
    initial: {y: "-1000px"},
    exit: {y: -1000},
    transition: {duration: 0.2},
  };

  return (
    <>
      {width < 640 ? (
        <motion.header
          key={12}
          className="navbar-container z-20"
          animate={animation_smallscreen.animate}
          initial={animation_smallscreen.initial}
          exit={animation_smallscreen.exit}
          transition={animation_smallscreen.transition}
        >
          <div className="navbar-content items-center">
            <button onClick={() => history(-1)} className="navbar-text">
              <UseAnimations
                className="navbar-icon"
                speed={1}
                animation={arrowUp}
                size={35}
                strokeColor="black"
                wrapperStyle={{
                  transform: "rotate(270deg)",
                }}
              />
              Back
            </button>

            <Link to={"/contact"}>
              <div className="navbar-text">
                <FontAwesomeIcon
                  icon={faLink}
                  className="text-black my-auto mr-1"
                />
                Links
              </div>
            </Link>
            {/* <Link to={"/" + next_id} className="navbar-text">
          Next
          <UseAnimations
            className="navbar-icon"
            speed={1}
            animation={arrowUp}
            size={35}
            strokeColor="black"
            wrapperStyle={{
              transform: "rotate(90deg)",
            }}
          />
        </Link> */}
          </div>
        </motion.header>
      ) : (
        <></>
      )}
    </>
  );
}
